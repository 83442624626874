<template>
  <div id="judge-report">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="right" cols="12" md="12">
            <v-btn
              @click.prevent="refreshPageData"
              color="#a4c639"
              min-width="92"
              rounded
              medium
              class="font-size-h6 px-3 py-3 white--text"
              >Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- <v-card-subtitle>
          <h5>{{ PageDescription }}</h5>
        </v-card-subtitle> -->
      <v-card-text>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="searchForm"
        >
          <div
            class="d-flex justify-content-center mt-2 mb-3"
            v-if="LoadingFlag"
          >
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-row>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6><span class="text-danger">*</span> Organization Type</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="OrganizationTypeOptions"
                :rules="OrganizationTypeRules"
                v-model="OrganizationType"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="6" v-if="OrganizationType == 2">
              <h6><span class="text-danger">*</span> Zone</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SubmitFlag"
                medium
                rounded
                color="#1db954"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Search
              </v-btn>
              <v-btn
                @click.prevent="resetAll"
                medium
                rounded
                color="brown"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
          <h3 class="red--text text-center mt-3 mb-2">
            {{ tableData1.length }} records found.
          </h3>
          <v-container v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#4d908e"
                  style="height: 150px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#4d908e"
                  id="card1"
                  style="height: 150px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>Total Awards</h5>
                    <br />
                    <h1>{{ TotalAwards }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#43aa8b"
                  style="height: 150px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#43aa8b"
                  id="card1"
                  style="height: 150px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>Completed Awards</h5>
                    <br />
                    <h1>{{ CompletedAwards }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="4">
                <v-card
                  color="#f3722c"
                  style="height: 150px"
                  v-if="CardLoadingFlag"
                >
                  <br />
                  <v-col cols="12" v-for="n in 3" :key="n">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="text"
                    ></v-skeleton-loader>
                    <br />
                  </v-col>
                </v-card>
                <v-card
                  color="#f3722c"
                  id="card1"
                  style="height: 150px"
                  v-if="!CardLoadingFlag"
                >
                  <v-card-text id="card-text">
                    <h5>Pending Awards</h5>
                    <br />
                    <h1>{{ PendingAwards }}</h1>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:[`item.Total`]="{ item }">
                    <v-btn
                      :color="item.TotalColor"
                      fab
                      small
                      class="font-size-h6 white--text"
                    >
                      {{ item.Total }}
                    </v-btn>
                  </template>
                  <template v-slot:[`item.TotalCompleted`]="{ item }">
                    <v-btn
                      :color="item.TotalColor"
                      fab
                      small
                      class="font-size-h6 white--text"
                    >
                      {{ item.TotalCompleted }}
                    </v-btn>
                  </template>
                  <template v-slot:[`item.TotalPending`]="{ item }">
                    <v-btn
                      :color="item.TotalPendingColor"
                      fab
                      small
                      class="font-size-h6 white--text"
                    >
                      {{ item.TotalPending }}
                    </v-btn>
                  </template>
                  <template v-slot:[`item.ContactTxt`]="{ item }">
                    <p v-html="item.ContactTxt"></p>
                  </template>
                  <template v-slot:[`item.Actions`]="{ item }">
                    <v-tooltip bottom v-if="item.PreviewFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.EditButtonFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="warning"
                          @click="editAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteButtonFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="#ff0000"
                          @click="deleteAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span> Delete </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="80%">
      <awards-preview
        :previewRecordPrompt="previewRecordPrompt"
        :recordData="recordData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt($event)"
        v-if="previewRecordPrompt"
      ></awards-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AwardsPreview from "@/view/pages/erp/jci-award/AwardsPreview.vue";

export default {
  mixins: [common],
  components: { AwardsPreview },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      OverlayLoadingFlag: false,
      CardLoadingFlag: false,
      search: "",

      ProgessStart: 0,
      uploadPercentage: 0,

      UserInterface: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      TotalAwards: 0,
      CompletedAwards: 0,
      PendingAwards: 0,

      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationType: "",
      OrganizationTypeOptions: [
        { value: 2, text: "Zone Level" },
        { value: 1, text: "National Level" },
      ],
      OrganizationTypeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      recordData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      ActiveStatus: "",

      valid: true,

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = false;
    },
    OrganizationType: function () {
      console.log("watch OrganizationType called");
      this.ZoneCode = "";
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "award_report",
        Action: "report_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getZoneCodeOptions();
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetAll() {
      console.log("resetAll called");
      this.$refs.form1.reset();
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ExcelFields = {};

      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.sweetAlert("warning", message, false);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.errorMessage = "";
        this.successMessage = "";

        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judge-award-relation/judges-lists";

        var upload = {
          UserInterface: 2,
          OrganizationType: this.OrganizationType,
          Zone: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log({ server_url }, { token }, { add_url });

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log({ output }, { flag });

            var options = records.TableOptions;

            thisIns.tableOptions1 = options;

            var total = 0;
            var completed = 0;
            var pending = 0;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              console.log("tableData1 = ", thisIns.tableData1);
              thisIns.toast("success", output);

              records.TableData.forEach((row) => {
                total += row["Total"];
                completed += row["TotalCompleted"];
                pending += row["TotalPending"];
              });

              thisIns.TotalAwards = total;
              thisIns.CompletedAwards = completed;
              thisIns.PendingAwards = pending;
            } else {
              console.log({ output });
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log({ error });
            thisIns.LoadingFlag = false;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#judge-report {
  #card-text {
    text-align: center;
    color: white;
  }
  #card-text h5 {
    font-size: 34px;
  }
  #card-text h1 {
    font-size: 50px;
    font-weight: 900;
  }
}
</style>
